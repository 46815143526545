
.hm-dialog{
    margin-top: 100px;
    max-width: 90vw;
    max-height: 70vh;
}

.hm-dialog-content{
    max-height: calc( 70vh - 100px );
    overflow: auto;
}

@media only screen and (max-width: 400px) {
    .hm-dialog{
        max-width: 100vw;
        max-height: 100vh;
    }
    
    .hm-dialog-content{
        max-height: calc( 100vh - 300px );
        overflow: auto;
    }
}