
.bg-color1{
    background: var(--color1);
}
.bg-color2{
    background: var(--color2);
}
.bg-color3{
    background: var(--color3);
}
.bg-color4{
    background: var(--color4);
}
.hover\:bg-color1:hover{
    background: var(--color1);
}
.hover\:bg-color2:hover{
    background: var(--color2);
}
.hover\:bg-color3:hover{
    background: var(--color3);
}
.hover\:bg-color4:hover{
    background: var(--color4);
}

.text-color1{
    color: var(--color1);
}
.text-color2{
    color: var(--color2);
}
.text-color3{
    color: var(--color3);
}
.text-color4{
    color: var(--color4);
}
.hover\:text-color1:hover{
    color: var(--color1);
}
.hover\:text-color2:hover{
    color: var(--color2);
}
.hover\:text-color3:hover{
    color: var(--color3);
}
.hover\:text-color4:hover{
    color: var(--color4);
}


.border-color1{
    border-color: var(--color1);
}
.border-color2{
    border-color: var(--color2);
}
.border-color3{
    border-color: var(--color3);
}
.border-color4{
    border-color: var(--color4);
}


.bg-healthmotiv {
    background: url("../../static/img/bgdoctorwhite.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.bg-grey-gradient {
    background-image: linear-gradient(to bottom, #fff 60%, #CCC 100%);
}

.bg-sidemenu-left{
    background: url("../../static/img/sidemenu.jpg");
    background-size: cover;
    background-position: center;
    box-shadow: 2px 0px 5px rgba(0,0,0,0.3);
    z-index:2;
    width: 240px;
    max-height: calc( 100vh - 85px );
}
.bg-sidemenu-center{
    background: url("../../static/img/sidemenu.jpg");
    background-size: cover;
    background-position: center;
    background-color: #ccc;
    box-shadow: 2px 0px 5px rgba(0,0,0,0.3);
    z-index:1;
    width: 240px;
    max-height: calc( 100vh - 85px );
}

.bg-grey-gradient\:hover:hover {
    background-image: linear-gradient(to bottom, #fff 20%, #CCC 90%)
}

.bg-button{
    box-shadow: 0 0 3px rgba(0,0,0, 0.3), 0 0 1px rgba(0,0,0, 0.3);
    border-radius: 4px;
    padding: 12px 24px;
    background: linear-gradient(to bottom, #fff 60%, #aaa 95%);
    transition: all 300ms;
    color: black;
    background-size: 100% calc( 100% + 5px );
    display: flex;
    flex-direction: row;
    font-size: 13px;
}

.bg-button[disabled], .bg-button:disabled{
    color: #aaa;
}

.bg-button:hover{
    background-position: 0 -3px;
}

.bg-button:active{
    background-position: 0 -5px;
}

button.close-dialog{
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    width: 34px;
    height: 34px;
    font-size: 24px;
    text-align: center;
    line-height: 34px;
    background: var(--color1);
    color: #FFF;
    border: 2px solid white;
    -webkit-border-radius: 19px;
    -moz-border-radius: 19px;
    border-radius: 19px;
    z-index: 1002;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}


.h-mainarea {
    min-height: 100vh;
    padding-top: 85px;
}
.h-mainarea.pt-0 {
    padding-top: 0px;
}
.h-mainarea > div.h-main-full{
    min-height: calc( 100vh - 85px );
}


@media only screen and (max-width: 640px) {
    /* .h-mainarea {
        padding-top: 50px;
    }

    .h-mainarea > div{
        min-height: calc( 100vh - 50px );
    } */
    .bg-sidemenu-center{
        width: 100vw;
    }
    .bg-sidemenu-left{
        width: 100vw;
    }
}

.fullscreen .header{
    display: none;
}
.fullscreen .h-mainarea{
    padding-top: 0;
}
.fullscreen .max-h-mainarea{
    max-height: 100vh;
}
.fullscreen .bg-sidemenu-left{
    max-height: 100vh;
}
.fullscreen .bg-sidemenu-center{
    max-height: 100vh;
}
.fullscreen .h-mainarea > div.h-main-full{
    min-height: 100vh;
}


@media only screen and (max-height: 400px) {
    .header{
        display: none;
    }
    .h-mainarea{
        padding-top: 0;
    }
    body .max-h-mainarea{
        max-height: 100vh;
    }
    .bg-sidemenu-left{
        max-height: 100vh;
    }
    .bg-sidemenu-center{
        max-height: 100vh;
    }
    .h-mainarea > div.h-main-full{
        min-height: 100vh;
    }
    .keyboard\:hidden{
        display: none
    }
}

body{
    position: relative;
    font-family: "Noto Sans", "Sans";
}

.icon{
    font-family: "Icomoon";
    font-style: normal;
}

.text-xxs {
    font-size: 0.4rem;
}

.text-18px {
    font-size: 18px;
}

.max-h-mainarea{
    max-height: calc(100vh - 85px);
}

.bg-transparent{
    background: rgba(255, 255, 255, 0.5);
}

.bg-transparent-200{
    background: rgba(255, 255, 255, 0.2);
}

.hover\:bg-transparent:hover{
    background: rgba(255, 255, 255, 0.5);
}

.hover\:bg-transparent-200:hover{
    background: rgba(255, 255, 255, 0.2);
}

.min-w-1\/2 {
    min-width: 50%;
}

.min-w-1\/3 {
    min-width: 33%;
}

.shadow-dark{
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5),  0 0 1px rgba(0, 0, 0, 0.9)
}

@font-face{
	font-family: 'icomoon';
	/* src: url('../../static/fonts/icomoon.eot?-vlkxpn'); */
	src: url('../../static/fonts/icomoon.eot?#iefix-vlkxpn') format('embedded-opentype'), url('../../static/fonts/icomoon.woff?-vlkxpn') format('woff'), url('../../static/fonts/icomoon.ttf?-vlkxpn') format('truetype'), url('../../static/fonts/icomoon.svg?-vlkxpn#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}
