$size16: 16px
$responsive_width: 800px

.cms
    text-align: left
    margin-left: 20px
    margin-bottom: 1em
    font-size: $size16

    h1
        font-size: 2em
    h2
        font-size: 1.5em
    h3
        font-size: 1.2em

    a
        color: var(--color1)

    h1, h2, h3
        margin-top: 0.5em
    h2
        margin-bottom: 0.3em

    ul
        padding: 0.5em 0em 0.5em 2em

    table
        border-collapse: collapse
        width: 100%

    ol, ul
        padding-left: 2em

    p, div.line-block .line
        padding-top: 1em
    .nonimportant
        font-size: 8px
        color: #bbb
        text-align: right
        margin-right: 2em

    &.header
        margin-bottom: 1em
        @media (max-width: $responsive_width)
            margin-top: 4em

    @media (max-width: $responsive_width)
        margin-left: 0px
        img
            width: 100%
            height: auto
