@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --color1: #82bc00;
    --color2: #a5cc2b;
    --color3: #bfe068;
    --color4: #0082bc;
    --menubgcolor: #a5cc2b;
}

@import "./fixes.css";
@import "./dialog.css";
@import "./cms.sass";
